@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&family=Roboto:wght@400;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css');

.services {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 2rem;
    padding-top: 140px;
}

.main-text > span {
    display: flex;
    font-size: 50px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    text-align: center;
    
    
    color: #082b2a;
    font-family: 'Roboto', sans-serif;
}

.subtext > span {
    color: #19887f;
    align-items: center;
    text-align: center;
    display: block;
    font-size: 25px;
    padding-right: 100px;
    padding-left: 100px;
    font-family: 'Roboto', sans-serif;
}

.cards {
    display: flex;
    gap: 2rem;
    justify-content: center;
}

.card {
    width: 100%;
    max-width: 300px;
    height: 450px;
    padding: 20px;
    box-shadow: 0 0 0 0.05rem hsla(214, 88%, 27%, 0.08), 0 0 1.25rem hsla(216, 14%, 14%, 0.06);
    border-radius: 10px;
    background: linear-gradient(90deg, rgba(54, 181, 166, 1) 35%, rgba(24, 109, 103, 1) 100%);
    box-shadow: 0px 0px 20px rgba(71, 184, 255, 0.5), 
                0px 5px 5px -1px rgba(58, 125, 233, 0.25), 
                inset 4px 4px 8px rgba(175, 230, 255, 0.5), 
                inset -4px -4px 8px rgba(19, 95, 216, 0.35);
    position: relative;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    overflow: hidden;
}

.card > img {
    width: 80px;
    height: auto;
    position: absolute;
    top: 20px;
    left: 37%;
    transform: translateX(-50%);
}

.card > div > span {
    font-size: 23px;
    font-weight: bold;
    margin-bottom: 18px;
    display: block;
    font-family: 'Roboto', sans-serif;
    margin-top: 100px;
    padding-top: 10px;
}

.card > ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
}

.card > ul > li {
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 15px;
    color: #ffffff;
    position: relative;
    padding-left: 30px;
    text-align: left;
    font-family: 'Roboto', sans-serif;
}

.card > ul > li::before {
    content: '\f5c9';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    position: absolute;
    left: 0;
    top: 0;
    color: #ffffff;
    font-size: 18px;
}

.services-blur {
    width: 20rem;
    height: 30rem;
    right: 0px;
    opacity: 0.8;
}

.services-blur-l {
    width: 20rem;
    height: 30rem;
    left: 0px;
    opacity: 0.8;
}
@media screen and (max-width: 768px) {
    /* Main text adjustments for smaller screens */
    .main-text > span {
        font-size: 30px; /* Adjusted font size */
        padding: 0 20px; /* Reduced horizontal padding */
        text-align: center; /* Center-align text */
        width: 110%; /* Ensure it covers more horizontal area */
    }
    
    /* Subtext adjustments for smaller screens */
    .subtext > span {
        font-size: 18px; /* Adjusted font size */
        padding: 0;
        margin-left: -15px; /* Reduced horizontal padding */
        text-align: center; /* Center-align text */
        width: 110%; /* Ensure it covers more horizontal area */
    }
    
    /* Cards adjustments for smaller screens */
    .cards {
        flex-direction: column; /* Stack cards vertically */
        align-items: center; /* Center align cards */
    }

}@media screen and (max-width: 768px) {
    .buttn {
      padding: 0.7em 1.2em; /* Adjust padding for smaller screens */
      min-width: 100px; /* Adjust minimum width */
      min-height: 38px; /* Adjust minimum height */
      font-size: 0.9rem; /* Adjust font size */
    }
  }
  .buttn {
    --clr-font-main: hsla(0 0% 20% / 100);
    --btn-bg-1: hsla(194 100% 69% / 1); /* Original styles can be removed if not needed */
    --btn-bg-2: hsla(217 100% 56% / 1);
    --btn-bg-color: hsla(360 100% 100% / 1);
    --radii: 0.5em;
  
    cursor: pointer;
    padding: 0.9em 1.4em;
    min-width: 120px;
    min-height: 44px;
    font-size: var(--size, 1rem);
    font-family: "Segoe UI", system-ui, sans-serif;
    font-weight: 500;
    transition: 0.8s;
    border: none;
    border-radius: var(--radii);
    color: var(--btn-bg-color); /* Adjust if needed */
  
    /* Modified background for button */
    background: linear-gradient(90deg, rgba(54, 181, 166, 1) 35%, rgba(24, 109, 103, 1) 100%);
    box-shadow: 0px 0px 20px rgba(71, 184, 255, 0.5), 0px 5px 5px -1px rgba(58, 125, 233, 0.25), inset 4px 4px 8px rgba(175, 230, 255, 0.5), inset -4px -4px 8px rgba(19, 95, 216, 0.35);
  }
  
  @media screen and (max-width: 768px) {
    .buttn {
      padding: 0.7em 1.2em; /* Adjust padding for smaller screens */
      min-width: 100px; /* Adjust minimum width */
      min-height: 38px; /* Adjust minimum height */
      font-size: 0.9rem; /* Adjust font size */
    }
  }
    