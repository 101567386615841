

.header {

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
  
}

.logo {
    max-width: 100%;
    max-height: 90%;
    margin-left: -30px;
    margin-top: 10px;
   
}

.header-menu {
    list-style: none;
    display: flex;
    gap: 2rem;
    color: #186D67;
    font-size: 25px;
    padding-top: 0px;
    font-family: 'Roboto', sans-serif;
    transition: transform 0.3s ease-in-out;
}

.header-menu > li:hover {
    cursor: pointer;
    color: #a1eee0;
}

.navTrigger {
  display: none;
    position: relative;
    z-index: 1000;
    width: 36px;
    height: 36px;
    background: none;
    border: 0;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.navTrigger svg {
  display: none;
    width: 64px;
    height: 48px;
    top: -6px;
    left: -14px;
    stroke:#19887f;
    stroke-width: 4px;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
    position: absolute;
}

.navTrigger svg path {
  display: none;
    transition: stroke-dasharray 0.85s ease, stroke-dashoffset 0.85s ease;
    stroke-dasharray:27px 100px;
    stroke-dashoffset: 126px;
}

.navTrigger svg path:nth-child(2) {
  display: none;
    transition-duration: 0.7s;
    stroke-dashoffset: 275px;
    stroke-dasharray: 100px;
}


.navTrigger.active svg {
  display: none;
    stroke: white;
}

.navTrigger.active svg path {
  display: none;
    stroke-dashoffset: 59px;
}

.navTrigger.active svg path:nth-child(1),
.navTrigger.active svg path:nth-child(3) {
  display: none;
    transition-delay: 0.15s;
    transition-timing-function: cubic-bezier(.2, .4, .2, 1.1);
}

.navTrigger.active svg path:nth-child(2) {
    transition-duration: 0.4s;
    display: none;
    stroke-dashoffset: 276px;
    stroke-dasharray: 100px;
}

.navTrigger.active svg path:nth-child(3) {
  display: none;
    stroke-dashoffset: 53px;
}

@media screen and (max-width: 768px) {
.header{
  padding: 0;

}
.logo{
margin-left: -60px;

}
  
    .header-menu {
      
        list-style:disc;
       position: fixed;
        top: 60px;
        left: 238px;
        box-shadow: 0px 0px 20px rgba(71, 184, 255, 0.5), 
                0px 5px 5px -1px rgba(58, 125, 233, 0.25), 
                inset 4px 4px 8px rgba(175, 230, 255, 0.5), 
                inset -4px -4px 8px rgba(19, 95, 216, 0.35);
        background:linear-gradient(90deg, rgba(54, 181, 166, 1) 35%, rgba(24, 109, 103, 1) 100%);
        flex-direction: column;
        align-items: center;
        font-weight: bold
        ;
        width: 37%;
        height: auto;
        
        transform: translateX(100%);
        border-radius: 20px;
        z-index: 999;
    }

    .header-menu {
      
      display: none; /* Start with display none */
      gap: 2rem;
      color: white;
      font-size: 18px;
      background-color: white;
      padding:20px;
      align-items: flex-start;
      padding-left: 25px;
      font-family: 'Roboto', sans-serif;
      transition: transform 0.3s ease-in-out;
  }
  
  .header-menu.active {
   
      display: flex; /* Display when active */
      transform: translateX(0);
  }
    .header-menu li {
      
      list-style: disc;
        opacity: 0;
        transform: translateY(-10px);
        transition: opacity 0.3s ease, transform 0.3s ease;
    }

    .header-menu.active li {
      
        opacity: 1;
        transform: translateY(0);
    }

    .navTrigger {
      display: flex;
     position: fixed;
    right:18px;
       
    }
    
    .navTrigger svg {
      display: block;
       
    }
    
    .navTrigger svg path {
      display: block;
    }
    
    .navTrigger svg path:nth-child(2) {
      display: block;
       
    }
    
    .navTrigger svg path:nth-child(3) {
    
        display: block;
        
    }
    
    .navTrigger.active svg {
      display: block;
        
    }
    
    .navTrigger.active svg path {
      display: block;
       
    }
    
    .navTrigger.active svg path:nth-child(1),
    .navTrigger.active svg path:nth-child(3) {
      display: block;
    }
    
    .navTrigger.active svg path:nth-child(2) {
    
        display: block;
       
    }
    
    .navTrigger.active svg path:nth-child(3) {
      display: block;
      
    }
    
}
