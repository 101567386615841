.footer-f {
  position: relative;
}

.waves > use {
  animation: move-forever 2s -2s linear infinite;
}

.waves > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 6s;
}

.waves > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 3s;
}

@keyframes move-forever {
  0% {
      transform: translate(-90px, 0%);
  }
  100% {
      transform: translate(85px, 0%);
  }
}

.footer-wave {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 30vw;
  max-height: 200px;
}
.view {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Creates 3 columns with equal width */
}

.view.grid-list {
  gap: 20px;
  padding: 2rem;
  background-color: #082b2a;
  padding-top: 50px;
  padding-bottom: 200px;
  font-family: Arial, sans-serif;
}

.logo {
  max-width: 300px;
  padding-top: 12px;
  padding-bottom: 10px;
}
.company {
  font-size: 18px;
  line-height: 1.5;
  color: #aaa;
  padding-left: 30px;
}
.footer-brand {
  padding-left: 50px;
}
.footer-text {
  font-size: 18px;
  line-height: 1.5;
  color: #aaa;
}
.social-list {
  display: flex;
  gap: 10px;
  margin-top: 20px;
  
  list-style: none;
  padding-left: 40px;
}

.social-link {
  display: inline-block;
  width: 40px;
  height: 40px;
  background-color: #19887f;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  transition: background-color 0.3s;
}

.social-link:hover {
  background-color: #a1eee0;
}

/* Footer lists styling */
.footer-list {
  padding-top: 35px;
  padding-left: 150px;
  list-style: none;
}

.footer-list-title {
  font-size: 30px;
  margin-bottom: 15px;
  color: #fff;
}

.footer-link {
  display: block;
  font-size: 18px;
  color: #aaa;
  text-decoration: none;
  margin-bottom: 10px;
  transition: color 0.3s;
  cursor: pointer;
}

.footer-link:hover {
  color: #a1eee0;
}

address.footer-text {
  font-style: normal;
  margin-bottom: 10px;
}

.btn3 {
  --clr-font-main: hsla(0 0% 20% / 100);
  --btn-bg-1: hsla(194 100% 69% / 1); /* Original styles can be removed if not needed */
  --btn-bg-2: hsla(217 100% 56% / 1);
  --btn-bg-color: hsla(360 100% 100% / 1);
  --radii: 0.5em;
  cursor: pointer;
  padding: 0.9em 1.4em;
  min-width: 120px;
  min-height: 44px;
  font-size: var(--size, 1rem);
  font-family: "Segoe UI", system-ui, sans-serif;
  font-weight: 500;
  transition: 0.8s;
  border: none;
  border-radius: var(--radii);
  color: var(--btn-bg-color); /* Adjust if needed */
  /* Modified background for button */
  background: linear-gradient(90deg, rgba(54, 181, 166, 1) 35%, rgba(24, 109, 103, 1) 100%);
  box-shadow: 0px 0px 20px rgba(71, 184, 255, 0.5), 0px 5px 5px -1px rgba(58, 125, 233, 0.25), inset 4px 4px 8px rgba(175, 230, 255, 0.5), inset -4px -4px 8px rgba(19, 95, 216, 0.35);
}

.btn3:is(:hover, :focus-visible) {
  transform: translateY(-4px);
}

.butn {
  padding-top: 35px;
  padding-left: 150px;
}

@media screen and (max-width: 768px) {
  .view {
      display: flex;
      flex-direction: column;
      align-items: center;
  }
  .view.grid-list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }
  .footer-list,
  .butn {
      padding-left: 0;
      text-align: center;
  }
  .social-list {
      margin-right: 80px;
      justify-content: center;
  }

  footer-brand-brand {
    align-items: left;
    display: flex;
    flex-direction: column;
   
  }
  .logo{
    margin-right: 40px;
   transform: scale(0.8);
   margin-left: 0px;
   margin-right:15px;
  }
  .company{
    margin-left: 20px;
  }
}
