@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&family=Roboto:wght@400;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css');

.about {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    gap: 2rem;
    padding-top: 140px;
}

@media(min-width: 768px) {

    .about {
        flex-direction: row;
       
    }
}

.about-blur{
    width: 20rem;
    height: 30rem;
    right: 0px;
    opacity: 0.8;
}

.left-g {
    flex: 1.2;
    display: flex;
    justify-content: center;
}

.sideimg {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
}

.sideimg img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.right-g {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: flex-start;
}

.custom-bullets {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    list-style: none;
    color: #082b2a;
    gap: 20px;
    font-family: 'Roboto', sans-serif;
}

.custom-bullets li {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.custom-bullets li .header-about {
    display: flex;
    align-items: center;
    width: 100%;
}

.custom-bullets li .toothanim {
    font-size: 5rem;
    margin-right: 1rem; 
    font-size: 60px;
}

.custom-bullets li .expand-btn {
    font-size: 15px;
    background-color: transparent;
    border: none;
    color: #19887f;
    margin-left: auto; 
    font-family: 'Roboto', sans-serif;
}

.custom-bullets li span {
    font-size: 24px;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
}

.custom-bullets li .expand-content {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.custom-bullets li .expand-content .innertext {
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    color: #19887f;
    font-family: 'Roboto', sans-serif;
}

.main-text-a {
    font-size: 28px;
    font-family: sans-serif;
    color: #082b2a;
    text-align: left;
    font-family: 'Roboto', sans-serif;
}

.new {
    color: #19887f;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
}

.sub-text-a {
    color: #19887f;
    font-weight: bold;
    text-align: left;
    font-family: 'Roboto', sans-serif;
    font-size: x-large;
}

 @media(max-width: 767px){
    .about{
        padding-top:0;
    }
    .main-text-a {
        font-size: 24px;
    }
    .sub-text-a {
        font-size: large;
    }
    .custom-bullets li span {
        font-size: 20px;
    }
    .custom-bullets li .toothanim {
        font-size: 50px;
    }
    .custom-bullets li .expand-content .innertext {
        font-size: 16px;
    }
}
